import React, { Component } from 'react';
import UserNavMenu from './NavMenu/UserNavMenu';
import { Footer } from './Footer';
import { UserContext } from '../providers/UserProvider';

export class UserLayout extends Component {
  static displayName = UserLayout.name;
  static contextType = UserContext;

  render () {
    let auth = this.context;
    return (
      <div>
          <UserNavMenu />
          {this.props.children}
          <Footer />
      </div>
    );
  }
}