import React, { Component, createContext } from "react";

export const UserContext = createContext({
    authenticated: false,
    updateUser: () => {}
});

class UserProvider extends Component {
    state = {
        authenticated: false,
        updateUser: this.updateUser
    };

    updateUser = (authenticated) => {
        this.setState({ authenticated });
    };

    render() {
        const { children } = this.props;
        return (
        <UserContext.Provider value={this.state}>
            {children}
        </UserContext.Provider>
        );
    }
}

export const UserConsumer = UserContext.Consumer;
export default UserProvider;