import React, { Component } from 'react';
import { Container, Form, FormGroup, Label, Input, Button, Card, CardHeader, CardBody, UncontrolledAlert } from 'reactstrap';


export class InviteOthers extends Component {
    static displayName = InviteOthers.name;

    constructor(props) {
        super(props);
        this.state = {
            showAlert: false
        };
        
        this.handleSubmitBound = event => this.handleSubmit(event);
    }

    displayAlert = (bool) => {
        this.setState({
            showAlert: bool
        });
    }

    handleSubmit(event) {
        event.preventDefault();
  
        // If user is logged in, go to home page.
        window.location.href = '/home';
    }

    render() {
        return (
            <Container className="mx-auto align-items-center">
                <h2 className="display-4 text-center pt-5">Invite Others</h2>
                <h5 className="col-lg-6 col-sm-10 mx-auto text-center my-3 lead">
                    You have the ability to share the impact of donation with others and give them their own control over their notifications
                </h5>
                <div>
                    { this.state.showAlert && (<UncontrolledAlert className="text-center col-8 mx-auto">Invite sent!</UncontrolledAlert>) }
                </div>
                <Form className="col-lg-5 col-sm-8 my-5 mx-auto" onSubmit={this.handleSubmitBound}>
                    <Card className="box-shadow mb-3">
                        <CardHeader>
                            <h4 className='my-0 font-weight-normal'>Send Invite</h4>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Input name="email" placeholder="Enter their email address" type="email" id="email" />
                            </FormGroup>
                        </CardBody>
                    </Card>
                    <div className="align-items-center mx-auto text-center">
                        <Button className="col-5 my-3 text-center" onClick={this.displayAlert.bind(null, true)}>Invite</Button><br/>
                        {/* TODO: Hide if logged in */}
                        <Button className="col-3 text-center">Skip</Button>
                    </div>
                </Form>
            </Container>
        );
    }
}

