import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';

export class OurMission extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayHeader: props.displayHeader
        };
    }

    render() {
        return(
            <Container>
                { this.state.displayHeader ? (<h2 className="display-4 text-center pt-5">Our Mission</h2>) : <h2 className='text-center'>Our Mission</h2>}
                <p className="text-center my-3">
                    To show the impact of a single choice.
                </p>
            </Container>
        );
    }
}