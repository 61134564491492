import React, { Component } from 'react';
import { Container, Form, FormGroup, Label, Input, Button, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { NotificationControl } from './NotificationControl';
import { SetPassword } from './SetPassword';
import { ProfileSettings } from '../About/Profile';

export class ManageAccount extends Component {
    static displayName = ManageAccount.name;

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
              activeTab: tab
            });
        }
    }

    render() {
        return (
            <Container className="mx-auto align-items-center">
                <h2 className="display-4 text-center pt-5 mb-4">Manage Account</h2>
                <div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                Personal Information
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                Notifications
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                Change Password
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <ProfileSettings displayHeader={false} />
                        </TabPane>
                        <TabPane tabId="2">
                            <NotificationControl displayHeader={false} />
                        </TabPane>
                        <TabPane tabId="3">
                            <SetPassword displayHeader={false} />
                        </TabPane>
                    </TabContent>
                </div>
            </Container>
        )
    }
}