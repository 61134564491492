export default class UserAction {

    static LOGIN = 'UserAction.LOGIN';
    static LOGOUT = 'UserAction.LOGOUT';

    static logout() {
        return {
            type: UserAction.LOGIN
        }
    }

    static logout() {
        return {
            type: UserAction.LOGOUT
        }
    }
}