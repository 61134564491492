import React, { Component } from 'react';
import { Container, Form, FormGroup, Label, FormText, Input, Button, Card, CardHeader, CardBody } from 'reactstrap';

export class NotificationControl extends Component {
    static displayName = NotificationControl.name;

    constructor(props) {
        super(props);
        this.state = {
            displayHeader: props.displayHeader
        };
        
        this.handleSubmitBound = event => this.handleSubmit(event);
    }

    handleSubmit(event) {
        event.preventDefault();
  
        window.location.href = '/invite';
    }

    render() {
        return(
            <Container>
                { this.state.displayHeader ? (<h2 className="display-4 text-center pt-5">Notification Settings</h2>) : <h2 className='text-center pt-5'>Notification Settings</h2>}
                { this.state.displayHeader && (
                    <h5 className="col-lg-6 col-sm-10 mx-auto text-center my-3 lead">
                        We want you to have a choice and control when and how you receive information regarding your family members&apos; donations
                    </h5>
                )}
                <Form className="col-lg-5 col-sm-8 my-5 mx-auto" onSubmit={this.handleSubmitBound}>
                    <Card className="box-shadow mb-3">
                        <CardHeader>
                            <h4 className='my-0 font-weight-normal'>Frequency</h4>
                        </CardHeader>
                        <CardBody className='p-4 mb-3'>
                            <FormGroup check>
                                <Input name="frequency" type="radio" />
                                {' '}
                                <Label check>After every donation</Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input name="frequency" type="radio" />
                                {' '}
                                <Label check>Monthly Update</Label>
                                <FormText>For months when a donation was shared</FormText>
                            </FormGroup> 
                            <FormGroup check>
                                <Input name="frequency" type="radio" />
                                {' '}
                                <Label check>Yearly Update</Label>
                                <FormText>For years when a donation was shared</FormText>
                            </FormGroup>
                        </CardBody>
                    </Card>
                    <Card className="box-shadow mb-3">
                        <CardHeader>
                            <h4 className='my-0 font-weight-normal'>Method</h4>
                        </CardHeader>
                        <CardBody className='p-4 mb-3'>
                            <FormGroup check>
                                <Input name="method" type="radio" />
                                {' '}
                                <Label check>Email</Label>
                            </FormGroup>
                            <FormGroup check className="mb-3">
                                <Input name="method" type="radio" />
                                {' '}
                                <Label check>Text</Label>
                            </FormGroup>
                            <FormGroup>
                                <Label for="phone">If text, please provide a phone number</Label>
                                <Input name="phone" placeholder="Enter phone number" type="text" id="phone" />
                            </FormGroup>
                        </CardBody>
                    </Card>
                    <div className='text-center mx-auto'>
                        <Button className="col-4 my-3 text-center mx-auto">Save</Button>
                    </div>
                </Form>
            </Container>
        );
    }
}