import React, { Component } from 'react';
import { DropdownItem, DropdownMenu, NavLink } from 'reactstrap';
import './NavMenu.css';

export class UserNavMenuOptions extends Component {
    static displayName = UserNavMenuOptions.name;

    constructor (props) {
        super(props);

        this.state = {
            userLoggedIn: true,
            username: props.username
        }
    }

    render () {
        return (
            <DropdownMenu right>
                <DropdownItem href='notification'>Notification Control</DropdownItem>
                <DropdownItem href='invite'>Invite Others</DropdownItem>
                <DropdownItem href='manageaccount'>Manage Account Info</DropdownItem>
                <DropdownItem href='setpassword'>Change Password</DropdownItem>
                <DropdownItem href=''>Log Out</DropdownItem>
                <DropdownItem divider />
                <DropdownItem href='/mission'>Our Mission</DropdownItem>
                <DropdownItem href='/about'>About Us</DropdownItem>
                <DropdownItem href='/faq'>FAQ</DropdownItem>
                <DropdownItem href='/contact'>Contact Us</DropdownItem>
            </DropdownMenu>
        );
    }
}