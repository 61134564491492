import React, { Component, useContext } from 'react';
import { UserContext } from '../../UserProvider.js';
import { Container, Form, FormGroup, Label, Input, Button, Card, CardHeader, CardBody } from 'reactstrap';

export class SetPassword extends Component {
    static displayName = SetPassword.name;

    constructor(props) {
        super(props);
        this.state = {
            displayHeader: props.displayHeader
        };

        this.handleSubmitBound = event => this.handleSubmit(event);
    }

    handleSubmit(event) {
        event.preventDefault();
        
        window.location.href = './notification'
    }

    render() {
        return (
            <Container>
                { this.state.displayHeader ? (<h2 className="display-4 text-center pt-5">Set Password</h2>) : <h2 className='text-center pt-5'>Change Password</h2>}
                { this.state.displayHeader && (
                    <h5 className="col-lg-6 col-sm-10 mx-auto text-center my-3 lead">
                        Please enter a complex password for your new account
                    </h5>
                )}
                <Form className="col-lg-5 col-sm-8 my-5 mx-auto" onSubmit={this.handleSubmitBound}>
                    <Card className="box-shadow mb-3">
                        <CardHeader>
                            <h4 className='my-0 font-weight-normal'>Password</h4>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Input name="password" placeholder="Enter password" type="password" id="passwordInput" required />
                            </FormGroup>
                        </CardBody>
                    </Card>
                    <Card  className="box-shadow mb-3">
                        <CardHeader>
                            <h4 className='my-0 font-weight-normal'>Confirm Password</h4>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Input name="confirmPassword" placeholder="Confirm password" type="password" id="confirmPassword" required />
                            </FormGroup>
                        </CardBody>
                    </Card>
                    <div className='text-center mx-auto'>
                        <Button className="col-4 my-3 text-center mx-auto">Save</Button>
                    </div>
                </Form>
            </Container>
        );
    }
}