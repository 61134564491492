import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons'

export class UserButton extends Component {
    static displayName = UserButton.name;

    constructor (props) {
        super(props);

        this.state = {
            userLoggedIn: true,
            username: props.username
        }
    }

    render () {
        return (
            <Button color="primary" outline className='mr-3' href='home'>
                <FontAwesomeIcon icon={faUser} className='mr-2' />
                {this.props.username}
            </Button>
        )
    }
}