import React, { Component, useContext } from 'react';
import { Route } from 'react-router';
import { Container, Form, FormGroup, Label, Input, Button, Alert, Card, CardHeader, CardBody } from 'reactstrap';

export class Contact extends Component {
    static displayName = Contact.name;

    constructor(props) {
        super();
        this.state = {
            showAlert: false,
            displayHeader: props.displayHeader
        };

        this.handleSubmitBound = event => this.handleSubmit(event);
    }

    displayAlert = (bool) => {
        this.setState({
            showAlert: bool
        });
    }

    render() {
        return(
            <Container>
                { this.state.displayHeader ? (<h2 className="display-4 text-center pt-5">Contact Us</h2>) : <h2 className='text-center'>Contact Us</h2>}
                <div>
                    { this.state.showAlert && (<Alert className="text-center col-lg-8 col-sm-10 mx-auto">Thank you! We will respond to you shortly.</Alert>) }
                </div>
                <Form className="col-lg-5 col-sm-8 my-5 mx-auto" onSubmit={this.handleSubmitBound}>
                    <Card className="box-shadow mb-3">
                        <CardHeader>
                            <h4 className='my-0 font-weight-normal'>Your Information</h4>
                        </CardHeader>
                        <CardBody className="p-4">
                            <FormGroup>
                                <Label for="fullName">Name</Label>
                                <Input id="fullName" name="fullName" placeholder="Enter full name" type="text" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input id="email" name="email" placeholder="Enter email" type="email" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="phone">Phone Number</Label>
                                <Input name="phone" placeholder="Enter phone number" type="text" id="phone" />
                            </FormGroup>
                        </CardBody>
                    </Card>
                    <Card className="box-shadow mb-3">
                        <CardHeader>
                            <h4 className='my-0 font-weight-normal'>Comments</h4>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Input id="comments" name="comments" type="textarea" rows="5" placeholder="Enter your comments or questions here" />
                            </FormGroup>
                        </CardBody>
                    </Card>
                    <div className='text-center mx-auto'>
                        <Button className="my-3 text-center" onClick={this.displayAlert.bind(null, true)}>Submit</Button>
                    </div>
                </Form>  
            </Container>
        );
    }
}