import React, { Component } from 'react';
import './gift.css';

class Gift extends Component {
  static logosDirectory = "/img/logos/";

  constructor(props) {
    super(props);
    
    this.state = {};
    if (props.company && (props.quantity !== null) && (props.quantity !== undefined)) {
      this.state.logo = props.logo;
      this.state.logoClass = props.logoClass;
      this.state.company = props.company;
      this.state.quantity = props.quantity;
      this.state.email = props.email;
      this.state.phone = props.phone;
      this.state.quantity = props.quantity;
    } else {
      this.state.logo = null;
      this.state.logoClass = null;
      this.state.company = null;
      this.state.quantity = null;
      this.state.email = null;
      this.state.phone = null;
      this.state.quantity = null;
    }

    //this.setupCompanyValues = this.setupCompanyValues.bind(this);
  }

  // componentDidMount() {
  //   this.setupCompanyValues();
  // }

  // If the company is set in the state and we have directory information for the company
  // this function will populate the rest of the state values with the relevant company
  // data.
  // setupCompanyValues() {
  //   if (this.state.company === null || this.state.quantity === null)
  //     return;
    
  //   let companyData = dummyDirectory[this.state.company];
  //   this.setState({
  //     logo: companyData.logo,
  //     email: companyData.email,
  //     phone: companyData.phone
  //   });
  // }

  render() {
    // TODO make this responsive for phones
    if (this.state.company === null || this.state.company === undefined)
      return (<div></div>);

    let className = 'img-fluid ' + this.state.logoClass;

    return(
      <div className="row">
        <div className="col-4">
          <img className={className} src={Gift.logosDirectory + this.state.logo} alt={'Logo of the company ' + this.state.company}  />
        </div>
        <div className="col-8">
          <h2> {this.state.company} </h2>
          {this.state.email} <br />
          {this.state.phone} <br />
          Number of gifts: {this.state.quantity}
        </div>
      </div>
    );
  }
}

export default Gift;