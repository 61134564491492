import React, { Component } from 'react';
import { Button } from 'reactstrap';

export class GuestButton extends Component {
    static displayName = GuestButton.name;

    constructor (props) {
        super(props);

        this.state = {
            
        }
    }

    render () {
        return (
            <Button color="primary" className='mr-3' href="/signin">
                Sign In
            </Button>
        )
    }
}