import React from 'react';
import { Container, Card, CardHeader, CardBody } from 'reactstrap';

export function FAQ() {
    return(
        <Container>
            <h2 className="display-4 text-center pt-5">Frequently Asked Questions</h2>
            <div className="mx-auto mt-5">
                <Card className="box-shadow p-0 mb-3 col-lg-8 col-sm-10 mx-auto">
                    <CardHeader>
                        <h4 className='my-0 font-weight-normal'>Question 1</h4>
                    </CardHeader>
                    <CardBody>
                        <p>Answer 1</p>
                    </CardBody>
                </Card>
                <Card className="box-shadow p-0 mb-3 col-lg-8 col-sm-10 mx-auto">
                    <CardHeader>
                        <h4 className='my-0 font-weight-normal'>Question 2</h4>
                    </CardHeader>
                    <CardBody>
                        <p>Answer 2</p>
                    </CardBody>
                </Card>
                <Card className="box-shadow p-0 mb-3 col-lg-8 col-sm-10 mx-auto">
                    <CardHeader>
                        <h4 className='my-0 font-weight-normal'>Question 3</h4>
                    </CardHeader>
                    <CardBody>
                        <p>Answer 3</p>
                    </CardBody>
                </Card>
                <Card className="box-shadow p-0 mb-3 col-lg-8 col-sm-10 mx-auto">
                    <CardHeader>
                        <h4 className='my-0 font-weight-normal'>Question 4</h4>
                    </CardHeader>
                    <CardBody>
                        <p>Answer 4</p>
                    </CardBody>
                </Card>
                <Card className="box-shadow p-0 mb-3 col-lg-8 col-sm-10 mx-auto">
                    <CardHeader>
                        <h4 className='my-0 font-weight-normal'>Question 5</h4>
                    </CardHeader>
                    <CardBody>
                        <p>Answer 5</p>
                    </CardBody>
                </Card>
            </div>
        </Container>
    );
}