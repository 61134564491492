import React, { Component } from 'react';
import { Container, Form, FormGroup, Label, FormText, Input, Button, Card, CardHeader, CardBody } from 'reactstrap';

export class ProfileSettings extends Component {
    static displayName = ProfileSettings.name;

    constructor(props) {
        super(props);
        this.state = {
            displayHeader: props.displayHeader
        };

        this.handleSubmitBound = event => this.handleSubmit(event);
    }

    handleSubmit(event) {
        event.preventDefault();
  
        window.location.href = '/invite';
    }

    render() {
        return(
            <Container>
                { this.state.displayHeader ? (<h2 className="display-4 text-center pt-5">Profile Settings</h2>) : <h2 className='text-center pt-5'>Profile Settings</h2>}
                <Form className="col-lg-5 col-sm-8 my-5 mx-auto" onSubmit={this.handleSubmitBound}>
                    <Card className="box-shadow mb-3">
                        <CardHeader>
                            <h4 className='my-0 font-weight-normal'>Personal Information</h4>
                        </CardHeader>
                        <CardBody className="p-4 mb-3">
                            <FormGroup>
                                <Label for="firstname">First Name</Label>
                                <Input id="firstname" name="firstname" placeholder="Enter first name" type="text" required />
                            </FormGroup>
                            <FormGroup>
                                <Label for="lastname">Last Name</Label>
                                <Input id="lastname" name="lastname" placeholder="Enter last name" type="text" required />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email Address</Label>
                                <Input id="email" name="email" placeholder="Enter email" type="email" onChange={this.handleEmailBound} required />
                            </FormGroup>
                            <FormGroup>
                                <Label for="idKey">Unique Identification Key</Label>
                                <Input id="idKey" name="idKey" placeholder="4b08af74-8b24-463a-b212-7cb63ed87367" type="text" disabled />
                            </FormGroup>
                        </CardBody>
                    </Card>
                    <div className='text-center mx-auto'>
                        <Button className="col-4 my-3 text-center mx-auto">Save</Button>
                    </div>
                </Form>
            </Container>
        )
    }
}