import React, { Component, useContext } from 'react';
import { UserContext } from '../../UserProvider.js';
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Row from 'reactstrap/lib/Row';

export class Register extends Component {
  static displayName = Register.name;
  
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
    
    this.handleSubmitBound = event => this.handleSubmit(event);
  }

  handleSubmit(event) {
      event.preventDefault();

      window.location.href = '/setpassword';
  }

  render() {
      return(
        <Container>
            <h2 className="display-4 text-center pt-5">Donor Family Registration</h2>
            <h5 className="w-50 mx-auto text-center my-3 lead">
                Please enter the account information provided by your primary recovery organization
            </h5>
            <Form className="col-5 my-5 mx-auto" onSubmit={this.handleSubmitBound}>
                <FormGroup>
                    <Label for="email">Account (Email Address)</Label>
                    <Input id="email" name="email" placeholder="Enter email" type="email" onChange={this.handleEmailBound} required />
                </FormGroup>
                <FormGroup>
                    <Label for="idKey">Unique Identification Key</Label>
                    <Input id="idKey" name="idKey" placeholder="Enter identification key" type="text" />
                </FormGroup>
                <Row>
                <Button className="col-3 mx-auto my-3">Register</Button>
                </Row>
            </Form>
        </Container>
      );
  }
}