import React, { useRef, useState } from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import { Home } from './components/Home';
import { DonorGifts } from './components/DonorGifts/DonorGifts';
import DonorSignin from './components/DonorSignin/Signin';
import { DonorHome } from './components/DonorHome/DonorHome';
import { OurMission } from './components/About/OurMission';
import { About } from './components/About/About';
import { FAQ } from './components/About/FAQ';
import { Contact } from './components/Contact/Contact';
import { Register } from './components/DonorRegistration/Register';
import { SetPassword } from './components/Account/SetPassword';
import { NotificationControl } from './components/Account/NotificationControl';
import { InviteOthers } from './components/Account/InviteOthers';
import { ManageAccount } from './components/Account/ManageAccount';
import UserProvider from './providers/UserProvider';
import { UserLayout } from './components/UserLayout';
import { GuestLayout } from './components/GuestLayout';
import UserAuth from './components/DonorSignin/UserAuth';

// Bootstrap core CSS
import './vendor/bootstrap/css/bootstrap.min.css'

// Custom fonts for this Theme
import './vendor/fontawesome-free/css/all.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

// Custom styles for this Theme
import './css/landing-page.min.css'


function UserRoutes() {
  return(
      <UserLayout>
        <Route exact path='/' component={Home} />
          <Route exact path='/signin' component={DonorSignin} />
          <Route exact path='/home' component={DonorHome} />
          <Route path='/gifts' component={DonorGifts} />
          <Route path='/mission' component={OurMission} />
          <Route path='/about' component={About} />
          <Route path='/faq' component={FAQ} />
          <Route path='/contact' component={Contact} />
          <Route path='/registration' component={Register} />
          <Route path='/setpassword'>
              <SetPassword displayHeader={true} />
          </Route>
          <Route path='/notification'>
              <NotificationControl displayHeader={true} />
          </Route>
          <Route path='/invite' component={InviteOthers} />
        <Route path='/manageaccount' component={ManageAccount} />
      </UserLayout>
  );
}

function GuestRoutes() {
  return(
      <GuestLayout>
        <Route exact path='/' component={Home} />
          <Route exact path='/signin' component={DonorSignin} />
          <Route exact path='/home' component={DonorHome} />
          <Route path='/gifts' component={DonorGifts} />
          <Route path='/mission' component={OurMission} />
          <Route path='/about' component={About} />
          <Route path='/faq' component={FAQ} />
          <Route path='/contact' component={Contact} />
          <Route path='/registration' component={Register} />
          <Route path='/setpassword'>
              <SetPassword displayHeader={true} />
          </Route>
          <Route path='/notification'>
              <NotificationControl displayHeader={true} />
          </Route>
          <Route path='/invite' component={InviteOthers} />
        <Route path='/manageaccount' component={ManageAccount} />
      </GuestLayout>
  );
}

function App() {  
  // const auth = useRef();
  // const authHandler = (value) => {
  //   auth.current = value;
  // };
  // const [auth, setAuthenticated] = useState(false);
  // function toggleAuth() {
  //   setAuthenticated(prevAuth => !prevAuth)
  // }
  const setLogin = () => UserAuth.login;
  let auth = true;
  console.log((UserAuth.authenticated));
    return (
      <UserProvider>
        {/* {auth ? UserRoutes() : GuestRoutes()} */}
        <Layout authenticated={auth}>
          <Route exact path='/' component={Home} />
          <Route exact path='/signin'>
              <DonorSignin onSetAuth={setLogin} />
          </Route>
          <Route exact path='/home' component={DonorHome} />
          <Route path='/gifts' component={DonorGifts} />
          <Route path='/mission'>
              <OurMission displayHeader={true} />
          </Route>
          <Route path='/about'>
              <About displayHeader={true} />
          </Route>
          <Route path='/faq' component={FAQ} />
          <Route path='/contact'>
              <Contact displayHeader={true} />
          </Route>
          <Route path='/registration' component={Register} />
          <Route path='/setpassword'>
              <SetPassword displayHeader={true} />
          </Route>
          <Route path='/notification'>
              <NotificationControl displayHeader={true} />
          </Route>
          <Route path='/invite' component={InviteOthers} />
          <Route path='/manageaccount' component={ManageAccount} />
        </Layout>
      </UserProvider>
  );
}

export default App;
