import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';

export class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayHeader: props.displayHeader
        };
    }

    render() {
        return(
            <Container>
                { this.state.displayHeader ? (<h2 className="display-4 text-center pt-5">About Us</h2>) : <h2 className='text-center'>About Us</h2>}
                <p className='text-center p-4'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                    molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
                    numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
                    optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
                    obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam
                    nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit,
                    tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit,
                    quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos 
                    sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam
                    recusandae alias error harum maxime adipisci amet laborum. Perspiciatis 
                    minima nesciunt dolorem! Officiis iure rerum voluptates a cumque velit 
                    quibusdam sed amet tempora.
                </p>
            </Container>
        );
    }
}