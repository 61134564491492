import React, { Component, useContext, setProps, useRef } from 'react';
import { UserContext } from '../../providers/UserProvider';
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';
//import { connect } from 'react-redux';
import UserAction from '../../actions/UserActions';
import UserAuth from './UserAuth';

class DonorSignin extends Component {
  // static contextType = UserContext;
  
  login() {
    this.props.onSetAuth();
  };

    // Bundles the form data and submits it to the user registration API
    // function handleSubmit(event) {
    //   event.preventDefault();
      
      
    //   // TODO sign in through either our backend or go directly to the company
    //   //window.location.href = '/home'          // When this happens, the context gets switched back to default. Why??????????????????????????????
    // };

    // let auth = useRef();
    // const authHandler = () => {
    //   auth.current = false;
    //   console.log(auth.current);
    //   auth.current = true;
    //   console.log(auth.current);
    //   window.location.href = '/home';
    // }

    render() {
        return (
            <Container>
              <h2 className="display-4 text-center pt-5">Welcome</h2>
              <h5 className="col-6 mx-auto text-center my-3 lead">
                  Please sign in
              </h5>
              <Form className="col-5 my-5 mx-auto">
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input id="email" name="email" placeholder="Enter email" type="email" required />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input name="password" placeholder="Enter password" type="password" id="passwordInput" required />
                </FormGroup>
                  <Button className="my-3 text-center" onClick={this.login()}>Submit</Button>
              </Form>
            </Container>
        );
    }
}

// const mapStateToProps = state => ({
//   authenticated: state.authenticated
// });

// export default connect(mapStateToProps)(DonorSignin);

export default DonorSignin;
  
  /*
  State update Functions
  ----------------------
  Responsible for updating relevant state variables to reflect form contents.
  */
  function handleEmailUpdate(event) {
    this.setState({email: event.target.value});
  }
  
  
 function handlePasswordUpdate(event) {
    this.setState({password: event.target.value});
  }


