class UserAuth {

    state = {
        authenticated: false,
        login: this.login.bind(this),
        logout: this.logout.bind(this)
    };

    login() {
        this.setState({authenticated: !this.state.authenticated});
    }

    logout() {
        this.setState({authenticated: !this.state.authenticated});
    }
}

export default UserAuth;