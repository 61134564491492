import React, { Component, useState, useContext } from 'react';
// Previously contained: Collapse, NavbarBrand, NavbarToggler, NavItem
import { Container, Navbar, Button, NavbarBrand, Dropdown, DropdownToggle } from 'reactstrap';
import Nav from 'reactstrap/lib/Nav';
import './NavMenu.css';
import { UserButton } from './UserButton';
import { UserNavMenuOptions } from './UserNavMenuOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

function UserNavMenu() {
  const [collapsed, setCollapsed] = React.useState(false);
  const toggler = () => setCollapsed(prevState => !prevState);

    return (
      <>
        <Navbar bg="light" expand="lg">
          <Container>
            <NavbarBrand href="/home">
              <img src='/branding/Circle of Life - Logo 2.png' alt='Circle of Life' width='75px' height='75px' />
              <span className='d-none d-lg-inline'>Donor Family Registry</span>
            </NavbarBrand>
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
              >
                <UserButton username={'Todd'} />
                <Dropdown isOpen={collapsed} toggle={toggler}>
                  <DropdownToggle>
                    <FontAwesomeIcon icon={faBars} />
                  </DropdownToggle>
                  <UserNavMenuOptions />
                </Dropdown>
              </Nav>
          </Container>
        </Navbar>
      </>
    );
}

export default UserNavMenu;
