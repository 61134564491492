import React, { Component } from 'react';
import { CardBody } from 'reactstrap';
import Card from 'reactstrap/lib/Card';
// import { DonorSignUp } from '../DonorSignUp/DonorSignUp';
// import { Button } from 'reactstrap';
import { About } from '../About/About';
import { OurMission } from '../About/OurMission';
import { Contact } from '../Contact/Contact';

export class Home extends Component {
  static displayName = Home.name; 

    constructor(props) {
        super(props)

        this.onClickHandler = this.onClickHandler.bind(this);
    }

    onClickHandler() {
        // TODO: change with better routing
        window.location.href = "/registration";
    }

  render () {
    return (
        <>
            <header className="masthead text-white text-center">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 mx-auto">
                            <h2 className="display-3 text-center">Circle of Life Platform</h2>
                        </div>
                        <div className="col-12 col-md-7 mx-auto pt-5">
                            <button type="submit" className="btn col-5 btn-md mx-auto btn-primary" onClick={this.onClickHandler}>Sign Up</button>
                        </div>
                    </div>
                </div>
            </header>
            <Card className="box-shadow col-lg-8 col-sm-10 py-5 mb-3 mt-3 mx-auto h-100">
                <CardBody>
                    <OurMission displayHeader={false} />
                </CardBody>
            </Card>
            <Card className="box-shadow col-lg-8 col-sm-10 py-5 mb-3 mt-3 mx-auto">
                <CardBody>
                    <About displayHeader={false} />
                </CardBody>
            </Card>
            {/* <Card className="box-shadow col-8 py-5 mb-3 mt-3 mx-auto">
                <CardBody>
                    
                </CardBody>
            </Card> */}
            <div>
                <Contact displayHeader={false} />
            </div>
        </>
    );
  }
}
