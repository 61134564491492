import React, { Component, useRef } from 'react';
import UserNavMenu from './NavMenu/UserNavMenu';
import GuestNavMenu from './NavMenu/GuestNavMenu';
import { Footer } from './Footer';
import UserProvider, { UserContext } from '../providers/UserProvider';
//import { connect } from 'react-redux';
import UserAuth from './DonorSignin/UserAuth';
import { store, useGlobalState } from 'state-pool';


class Layout extends Component {
  static displayName = Layout.name;
  static contextType = UserContext;
  
  render () {
    return (
      <div>
          { this.props.authenticated ? <UserNavMenu /> : <GuestNavMenu /> }
          {this.props.children}
          <Footer />
      </div>
    );
  }
}

// const mapStateToProps = state => ({
//   authenticated: state.authenticated
// });

// export default connect(mapStateToProps)(Layout);

export default Layout;
