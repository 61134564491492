import React, { Component } from 'react';
import Gift from '../Gift/Gift.jsx';

export class DonorGifts extends Component {
    static displayName = DonorGifts.name;

    constructor(props) {
        super(props);
        this.state = {};

        // Initialize with info
        if (props.gifts != null) {
            this.state.gifts = props.gifts;
        } else {
            // Setup mock data
            this.data = {
                gifts: [
                    { logo: 'Medtronic-logo.png', logoClass: 'medtronic', company: 'Medtronic', email: "medtronic@test.com", phone: "(111) 111-1111", quantity: 30},
                    { logo: 'AlloSource_logo_tag_wh.png', logoClass: 'allosource', company: 'AlloSource', email: "allosource@test.com", phone: "(222) 222-2222", quantity: 50 },
                    { logo: 'smith-and-nephew-logo.png', logoClass: 'smithandnephew', company: 'Smith & Nephew', email: "smithandnephew@test.com", phone: "(333) 333-3333", quantity: 7 }
                ]
            };
            this.state.gifts = this.data.gifts;
        }

        this.state.totalGifts = 0;
        for (var i = 0; i < this.state.gifts.length; i++) {
            this.state.totalGifts += this.state.gifts[i].quantity;
        }
    }

    // TODO: change this to show each agency, then basic info about then, then the gifts related to their services
    // Using the ... spread command below on gift to pass all it's values to the Gift component
    static renderGifts(gifts) {
        return (
            <ul className="list-group">
                {gifts.map((gift, index) => 
                    <li key={index} className="list-group-item">
                        <Gift {...gift} />
                    </li>
                )}
            </ul>
        );
    }

    render() {
        let gifts = this.state.loading
            ? <p><em>Loading...</em></p>
            : DonorGifts.renderGifts(this.state.gifts);
    return (
        <div className="container">
            <div className="row">
                <div className="col-xl-12 mx-auto">
                    <h1 className="mb-5">John Smith has provided { this.state.totalGifts } gifts</h1>
                </div>
                <div className="col-md-10 mx-auto">
                    <div className="row">
                        <h2 className="col-lg-12 col-md-6">Gifts</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-6">
                            {gifts}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
