import React, { Component, createContext, setState } from "react";

const UserContext = createContext({ authenticated: false });

class UserProvider extends Component {

    state = {
        authenticated: true,
        signin: this.login.bind(this)
    };

    login() {
        this.setState({authenticated: !this.state.authenticated});
    }

    render() {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export { UserContext };
export default UserProvider;