import React, { Component } from 'react';
import GuestNavMenu from './NavMenu/GuestNavMenu';
import { Footer } from './Footer';
import { UserContext } from '../providers/UserProvider';

export class GuestLayout extends Component {
  static displayName = GuestLayout.name;
  static contextType = UserContext;
  
  render () {
    let auth = this.context;
    return (
      <div>
          <GuestNavMenu />
          {this.props.children}
          <Footer />
      </div>
    );
  }
}