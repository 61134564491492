import React, { Component } from 'react';
import { DropdownItem, DropdownMenu, NavLink } from 'reactstrap';
import './NavMenu.css';

export class GuestNavMenuOptions extends Component {
    static displayName = GuestNavMenuOptions.name;

    constructor (props) {
        super(props);

        this.state = {
            userLoggedIn: false,
        }
    }

    render () {
        return (
            <DropdownMenu right>
                <DropdownItem href='/mission'>Our Mission</DropdownItem>
                <DropdownItem href='/registration'>Register</DropdownItem>
                <DropdownItem href='/about'>About Us</DropdownItem>
                <DropdownItem href='/faq'>FAQ</DropdownItem>
                <DropdownItem href='/contact'>Contact Us</DropdownItem>
            </DropdownMenu>
        )
    }
}