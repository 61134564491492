import React, { Component } from "react";
import { Container } from "reactstrap";
import { About } from "../About/About";
import { OurMission } from "../About/OurMission";
import { Contact } from "../Contact/Contact";

export class DonorHome extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Container className="mx-auto align-items-center">
                <h2 className="display-4 text-center pt-5">Donor Family Home</h2>
            </Container>
        );
    }
}